.text {
  .inner {
    .title {
      font-size: 28px;
      line-height: 1.3;
      padding-bottom: $spacer;
      @extend .heading;

      // background-color: black;
      // color: white;
      margin: 0.5rem 0rem 2rem -4rem;
      padding: 0.5rem 0.5rem 0.5rem 4rem;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 36px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 48px;
      }
    }

    .description,
    .description p {
      font-size: 16px;

      @include media-breakpoint-up(md) {
        font-size: 20px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 24px;
      }
    }

    .title,
    .year,
    .description {
      display: block;
    }
  }
}
