.head {
  position: relative;
  overflow: hidden;
  height: 110vw;
  user-select: none;

  @include media-breakpoint-up(sm) {
    height: 100vw;
  }

  @media #{$landscape-s} {
    height: 40vw;
  }

  @include media-breakpoint-up(md) {
    height: 35vw;
  }

  @include media-breakpoint-up(lg) {
    height: 40vw;
  }

  @import 'head/scroll-down';
  @import 'head/simple-scroll';
  @import 'head/video-cover';
  @import 'head/header';
  @import 'head/shadow';
  @import 'head/glass';

  .inner {
    position: relative;
    z-index: 20;
  }

  // #######################################
  // HEAD BACKGROUND, VIDEO or IFRAME
  // #######################################

  // Z-index
  .bg {
    z-index: 1;
  }

  .iframe-container,
  .video-container {
    z-index: 2;
  }

  .video-container,
  .iframe-container,
  .bg {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .overlay {
    z-index: 10;
    pointer-events: none;
  }

  &.fullscreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
