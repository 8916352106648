#nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $navZ;
  user-select: none;
  font-size: 1.25rem;
  pointer-events: none;
  height: 100%;

  &.open {
    .popup {
      transform: translate3D(0, 0, 0);
    }

    .cover {
      // display: block;
    }
  }

  .cover {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    // display: none;
  }

  .ui {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    pointer-events: none;
  }

  .popup {
    position: absolute;
    pointer-events: auto !important;
    z-index: 2;
    width: 100%;
    height: 100%; // 400px;
    transform: translate3D(-100%, 0, 0);
    transition-duration: 0.4s;
    transition-property: transform;
    backface-visibility: hidden;
    overflow: hidden;
    background-color: white;
    // background: rgba(255, 255, 255, 0.9);
  }

  .nav-links > a {
    margin: 0 1rem 0 -1rem;
    font-size: 2rem;
    text-transform: uppercase;
    padding: 3px 1rem;
    cursor: pointer;
    display: block;
    margin-right: $spacer;
    margin-bottom: $spacer * 0.5;
    font-weight: 900;
    border: 1px solid transparent;

    @media (orientation: landscape) {
      display: inline-block;
    }

    .no-touchevents & {
      &:hover {
        border: 1px solid black;
      }
    }
  }

  .links-list {
    a {
      line-height: $line-height-lists;
      display: block;

      @media (orientation: landscape) {
        display: inline-block;
        margin-right: 2rem;
      }
    }
  }

  .name-box {
    margin-bottom: 2rem;
  }

  .nav-contact {
    margin-top: 5rem;

    @media (orientation: landscape) {
      margin-top: 3rem;
    }
  }
}
