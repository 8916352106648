// Global Z-index
$navZ: 100;
$contentZ: 10;
$webglZ: 1;


// Z-index for #nav
$maskZ: 5;
$offscreenZ: 2;
$btnZ: 10;
