.detail {
  z-index: $contentZ;
  min-height: 100%;
  position: relative;
  top: 0;

  // Default style
  &.default-template {
    @import 'detail/default/head';
    @import 'detail/default/text';
    @import 'detail/default/visuals';
    @import 'detail/default/iframe';
    @import 'detail/default/credits';
    @import 'detail/default/icons';
  }
}

// Custom style
@import 'detail/custom/the-airport-of-the-future';
@import 'detail/custom/w152';
@import 'detail/custom/fan-beat';
@import 'detail/custom/the-hunt';
@import 'detail/custom/pride-prejudice';
@import 'detail/custom/the-code-of-nature-3d';
@import 'detail/custom/glabs';
@import 'detail/custom/seda';
@import 'detail/custom/cliiq';
