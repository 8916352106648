#bio {
  // .intro {
  // }
  // .tech {
  // }

  .clients-list,
  .projects-list {
    line-height: $line-height-lists;
  }
}
