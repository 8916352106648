html,
body {
  height: 100%;
  // See: https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action
  touch-action: manipulation;
}

body {
  background-color: white;
  font-family: $font-family-sans-serif;
  line-height: 1.6;
  padding: 0;
  margin: 0;
  position: relative;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll;

  // &::-webkit-scrollbar,
  // &::-webkit-scrollbar-thumb,
  // &::-webkit-scrollbar-track {
  //   display: none;
  //   width: 0;
  //   height: 0;
  //   background: 0 0;
  // }
}

#root {
  position: relative;
  height: 100%;
  min-height: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  color: #333;

  > [data-reactroot] {
    position: relative;
    height: 100%;
  }

  .small-heading {
    font-family: $font-family-serif;
    font-weight: 400;
    text-transform: uppercase;
  }

  .heading {
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    font-weight: 900;
  }

  .img {
    display: block;
  }

  a,
  .link a {
    // color: $pulp;
    color: $black;
    text-decoration: none;
    transition-duration: 0.4s;
    border-bottom: 1px solid transparent;

    .no-touchevents & {
      &:hover {
        border-color: $black;
        // background-color: $pulp;
      }
    }
  }

  .dark {
    color: white;

    a,
    .link a {
      color: $mediumLightGrey;
      .no-touchevents & {
        &:hover {
          color: white;
          border-color: white;
        }
      }
    }
  }

  .link {
    cursor: pointer;
  }

  a:focus,
  button:focus,
  input:focus {
    outline: 0;
  }
}
