p {
  font-size: 1rem;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 56px;
}

h3 {
  font-size: 42px;
}