#fan-beat {
  background-color: #0b0d1a;
  color: white;

  // SAP colors
  .color1 {
    color: #b4ca48;
  }

  .color2 {
    color: #008fcc;
  }

  .color3 {
    color: #2b3f7b;
  }

  .color4 {
    color: #9c277b;
  }

  .color5 {
    color: #de8a2e;
  }
}
