a.list-item {
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  background-color: white;
  backface-visibility: hidden;
  cursor: pointer;
  display: block;
  border-color: transparent !important;

  $leftPadding: 0.62rem;

  @at-root .no-touchevents & {
    &:hover {
      text-decoration: none !important;
      .details {
        .name {
          transform: translate3D($leftPadding * 2, 0, 0);
        }
        .year,
        .intro {
          transform: translate3D($leftPadding * 2, 0, 0);
        }

        .name {
          // color: $pulp;
        }

        .intro {
          opacity: 1;
        }
      }
    }
  }

  @at-root .touchevents & {
    .details {
      .intro {
        transform: translate3D($leftPadding, 0, 0);
      }
    }
  }

  .image-wrapper {
    display: block;
    overflow: hidden;
    z-index: 5;
    position: relative;
    font-size: 7px;

    img {
      display: block;
      width: 100%;
      transition-duration: 0.3s;
      transition-timing-function: linear;
    }
  }

  .details {
    display: block;
    padding: 1rem 0;
    z-index: 10;
    position: relative;

    .name,
    .year,
    .intro {
      transition-duration: 0.4s;
      backface-visibility: hidden;
      font-size: 1rem;
      display: block;
    }

    .name {
      transform: translate3D($leftPadding, 0, 0);
      // color: $darkGrey;
      // color: $pulp;
      line-height: 1.5;
      @extend .heading;
      text-transform: uppercase;
      font-size: 1.1rem;
    }

    .intro {
      color: $mediumDarkGrey;
      transition-delay: 0.2s;

      @at-root .no-touchevents & {
        opacity: 0;
      }
    }

    .year {
      display: none;
    }
  }
}
