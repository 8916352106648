.video-container {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .video.cover {
    position: absolute;
  }
}
