.invisible {
  visibility: hidden;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.abs-fullscreen {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.rel-fullscreen {
  position: relative;
  width: 100%;
  height: 100vh;
}

.abs-percent-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

