.scroll-down {
  position: absolute;
  z-index: 10;
  bottom: $spacer;
  left: 50%;
  // cursor: pointer;
  transform: translateX(-50%);
  animation: scroll-down-anim 1s 2;
  // opacity: 0;

  // @media #{$m} {
  //   bottom: $marginX;
  // }

  .svg-icon {
    background-color: transparent;
    box-shadow: none;
    transform: scale(1.5) rotate(-90deg);

    &.white-chevron {
      > polygon {
        fill: white;
      }
    }
  }

  // &:hover {
  //   .svg-icon {
  //     @media #{$m} {
  //       box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  //     }
  //   }
  // }
}
