#pride-prejudice {
  .iframe-container {
    background-color: #fff;
  }

  .head {
    .bg {
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }

  .visuals {
    .art {
      &.zoomed {
        .img {
          border-left: none !important;
          border-right: none !important;
        }
      }
      .img {
        border: 1px solid $border-color;
      }
    }
  }
}
