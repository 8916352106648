.glass {
  position: absolute;
  z-index: 10;
  width: 200%;
  // height: 300px;
  height: 100%;
  // max-height: 75%;
  pointer-events: none;
  top: -50%;
  left: -50%;
  // left: 0;
  // bottom: 0;
  transform: rotate(-20deg);
  background-color: rgba(0, 0, 0, 0.06);
  // background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%,  rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.06) 11%, rgba(0, 0, 0, 0.06) 100%);

  
}