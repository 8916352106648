.iframe-container {
  position: relative;
  top: 0;
  left: 0;

  .iframe {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border: 0;
    user-select: none;
    display: block;
  }
}
