.page {
  z-index: $contentZ;

  h1 {
    padding: 0 0 20px 0;
    margin: 0;
    font-size: 2em;
    text-align: left;
  }

  h2 {
    text-align: left;
    padding: 20px 0 10px;
    margin: 0;
    font-size: 1.4em;
  }

  p {
    padding: 0;
    margin: 0;
  }
}
