// Colors
$superLightGrey: #f2f2f2;
$mediumLightGrey: #ddd;
$lightGrey: #dedede;
$mediumGrey: #ccc;
$mediumDarkGrey: #999;
$darkGrey: #666;
$black: #333;

// $pulp: #6653ff;
$pulp: #2646ea;

$border-color: #e9ecef;

// Size
$maxWidth: 100%;
$maxWidthMedium: 75%;
$maxWidthLarge: 75%;

// Margins mobile
$smallSpacer: 1rem;

// Margins tablet + desktop
$spacer: 2rem;

// Paths
$img-path: '../static/img/';
$fonts-path: '../static/fonts/';

// Responsive
$mMinHeight: 740px;
$iphone4MaxSize: 480px;

// Typo
$line-height-lists: 2.25;
