#the-hunt {
  $theHuntColor: #444444;

  background-color: $theHuntColor;

  .head {
    .border {
      border-color: #666 !important;
    }

    &.fullscreen {
      .border {
        border: none !important;
      }
    }
  }

  // .visuals {
  //   .art {
  //     &.zoomed {
  //       .img {
  //         border-left: none !important;
  //         border-right: none !important;
  //       }
  //     }
  //     .img {
  //       border: 1px solid #666;
  //     }
  //   }
  // }
}
