#the-code-of-nature-3d {
  $codeOfNatureColor: #cccccc;

  background-color: $codeOfNatureColor;

  // .border {
  //   border-color: #FFF !important;
  // }

  $border-color: #DDD;

  .visuals {
    .art {
      &.zoomed {
        .img {
          border-left: 0px solid transparent !important;
          border-right: 0px solid transparent !important;
        }
      }
      .img {
        border: 1px solid $border-color;
      }
    }
  }
}
