// Devices
//$below-tablet: 'only screen and (max-width : #{$tabletMinWidth}-1px), only screen and (max-height : #{$tabletMinHeight}-1px)';

// small devices is default (mobile first)
// $s: 'only screen and (max-width : #{$mMinWidth - 1})'; // below tablet
// $m: 'only screen and (min-width : #{$mMinWidth})'; // tablet-and-above
// $l: 'only screen and (min-width : #{$lMinWidth})'; // above-tablet
// $xl: 'only screen and (min-width : #{$xlMinWitdth})'; // above-desktop

$landscape-s: 'only screen and (max-height : #{$mMinHeight - 1}) and (orientation: landscape)';
$iphone4: 'only screen and (max-width : #{$iphone4MaxSize}) and (max-height : #{$iphone4MaxSize})';

// High density displays (like retina)
$retina: 'only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx)';

@media #{$retina} {
  // high density images here
}

@media #{$landscape-s} {
  .only-landscape-s {
    display: block;
  }
}

@media #{$iphone4} {
}
