.visuals {
  .art {
    &.vimeo {
      width: 100%;
      iframe {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        // width: 600px !important;
        // height: 400px !important;
      }
    }

    &.vimeo,
    &.image {
      &.no-zoom {
        cursor: auto;
      }

      .img {
        display: block;
        max-width: 100%;

        cursor: pointer;
      }
    }

    .caption {
      padding-top: 1rem;
      max-width: 100%; 
    }

    &.zoomed {
      // .img {
      //   border: none !important;
      // }

      .caption {
        padding-left: map-get($spacers, 2) !important;
        padding-right: map-get($spacers, 2) !important;

        @include media-breakpoint-up(md) {
          padding-left: map-get($spacers, 4) !important;
          @include make-col(8);
        }
      }
    }
  }
}
