#cliiq {
  $cliiqColor: white;
  $borderRadius: 5px;

  .visuals {
    .image {
      &.zoomed .img {
        border-radius: 0 !important;
      }

      .img {
        @include media-breakpoint-up(md) {
          border-radius: $borderRadius;
        }

        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .head {
    @include media-breakpoint-up(md) {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
      overflow: hidden;
    }

    .overlay.glass {
      // height: 40px;
      // display: none;
      // background-color: rgba(0, 0, 0, 0.16);
      top: -66%;

      @include media-breakpoint-up(md) {
        top: -50%;
      }

      // @include media-breakpoint-up(md) {
      //   background-color: rgba(0, 0, 0, 0.06);
      // }
    }

    .overlay.border {
      background-color: rgba(0, 0, 0, 0.1);

      @include media-breakpoint-up(md) {
        background: none;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }

    .bg {
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
}
